(function($) {

    // Scrollto link

    $(".scroll").bind("click",function(e){

        var
            href        = $(this).attr( 'href' ),
            html        = $('html, body');

        html.animate({scrollTop: $(href).offset().top});

        e.preventDefault();

    });

})(jQuery);
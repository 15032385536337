(function($) {

	$(".m-table-xcol-toggle__col").bind("click",function(){
		$(this).siblings().removeClass("m-table-xcol-toggle__col--isactive");
		$(this).addClass("m-table-xcol-toggle__col--isactive");
		var cellIndex = $(this).index();
		var headerIndex = $(this).index() + 1;
		$(this).parent().next("table").find("tr").each(function(){
			$(this).find(".m-table-xcol__col--isactive").removeClass("m-table-xcol__col--isactive");
			$(this).find("td:eq(" + cellIndex + ")").addClass("m-table-xcol__col--isactive");
			$(this).find("th:eq(" + headerIndex + ")").addClass("m-table-xcol__col--isactive");
		});
	});

})(jQuery);
(function($) {


	// Open menu (mobile)
	$(".m-menu__mobile-toggle").bind("click",function(){
		$(".m-menu__mobile-container").toggleClass("m-menu__mobile-container--isactive");
		$(".m-menu__mobile-toggle").toggleClass("m-menu__mobile-toggle--isactive");
	});


	// Sub-menus (mobile)
	$(".m-menu__mobile .menu-item-has-children > a").each(function() {

		$(this).bind("click",function(e){

			// First click opens the menu, second goes to page
			if (!$(this).parent().hasClass("m-menu__mobile--opensub")) {
				$(this).parent().addClass("m-menu__mobile--opensub");
				e.preventDefault();
			}

		});

	});


	// Open sub-menu (primary)

	$(".m-menu__primary > ul > .menu-item > a").each(function() {

		$(this).bind("focus mouseover",function(e){
			$(".m-menu__primary--opensub").removeClass("m-menu__primary--opensub");

			if($(this).parent().hasClass("menu-item-has-children")) {
				$(this).parent().addClass("m-menu__primary--opensub");
			}

		});

	});


	// Close sub-menu (primary)

	$(".m-header").bind("clickoutside focusoutside",function(){
		$(".m-menu__primary--opensub").removeClass("m-menu__primary--opensub");
	});


})(jQuery);
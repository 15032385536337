(function($) {

    $('.m-home-faq__slides').slick({
        arrows: false,
        dots: true,
        focusOnSelect: true,
        slidesToShow: 1,
    });

    $('.m-home-job__slides').slick({
        arrows: false,
        centerMode: true,
        dots: true,
        focusOnSelect: true,
        slide: 'article',
        slidesToShow: 2,
        responsive: [
            {
                breakpoint: 960,
                settings: {
                    centerMode: false,
                    focusOnSelect: true,
                    slidesToShow: 1
                }
            }
        ]
    });

})(jQuery);